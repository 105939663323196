import React from "react";
import { IoCloseSharp } from "@react-icons/all-files/io5/IoCloseSharp";
import clsx from "clsx";

type Props = {
  title?: string;
  color?: keyof typeof colorStyles;
  onClick?: () => void;
  selected?: boolean;
  clickable?: boolean;
  showClose?: boolean;
  className?: string;
};

const colorStyles = {
  blue: {
    base: "bg-blue-100 text-blue-800 dark:bg-blue-200 dark:text-blue-800",
    hover: "hover:bg-blue-200 dark:hover:bg-blue-300",
    selected: "bg-blue-300 dark:bg-blue-400"
  },
  dark: { 
    base: "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300",
    hover: "hover:bg-gray-200 dark:hover:bg-gray-600",
    selected: "bg-gray-300 dark:bg-gray-600"
  },
  red: { 
    base: "bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900",
    hover: "hover:bg-red-200 dark:hover:bg-red-300",
    selected: "bg-red-300 dark:bg-red-300"
  },
  green: { 
    base: "bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900",
    hover: "hover:bg-green-200 dark:hover:bg-green-300",
    selected: "bg-green-300 dark:bg-green-300"
  },
  yellow: { 
    base: "bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900",
    hover: "hover:bg-yellow-200 dark:hover:bg-yellow-300",
    selected: "bg-yellow-300 dark:bg-yellow-300"
  },
  amber: {
    base: "bg-amber-100 text-amber-600 dark:bg-amber-200 dark:text-amber-900",
    hover: "hover:bg-amber-200 dark:hover:bg-amber-300",
    selected: "bg-amber-300 dark:bg-amber-300"
  },
  violet: {
    base: "bg-violet-100 text-violet-600 dark:bg-violet-200 dark:text-violet-900",
    hover: "hover:bg-violet-200 dark:hover:bg-violet-300",
    selected: "bg-violet-300 dark:bg-violet-400"
  },
  indigo: { 
    base: "bg-indigo-100 text-indigo-800 dark:bg-indigo-200 dark:text-indigo-900",
    hover: "hover:bg-indigo-200 dark:hover:bg-indigo-300",
    selected: "bg-indigo-300 dark:bg-indigo-300"
  },
  purple: { 
    base: "bg-purple-100 text-purple-800 dark:bg-purple-200 dark:text-purple-900",
    hover: "hover:bg-purple-200 dark:hover:bg-purple-300",
    selected: "bg-purple-300 dark:bg-purple-300"
  },
  pink: { 
    base: "bg-pink-100 text-pink-800 dark:bg-pink-200 dark:text-pink-900",
    hover: "hover:bg-pink-200 dark:hover:bg-pink-300",
    selected: "bg-pink-300 dark:bg-pink-300"
  },
};

const Tag: React.FC<Props> = ({
  children,
  title,
  onClick,
  clickable = Boolean(onClick),
  className,
  color = "blue",
  selected = false,
  showClose,
}) => {
  const colors = colorStyles[color];

  return (
    <span
      onClick={onClick}
      className={clsx(
        colors.base,
        {
          "mx-1 rounded px-2 py-1 text-sm font-semibold": !className,
          [`cursor-pointer ${colors.hover}`]: clickable,
          [`${colors.selected}`]: selected
        },
        className
      )}
    >
      {title || children}{" "}
      {showClose && (
        <IoCloseSharp className="ml-0.5 mb-0.5 inline hover:text-red-600" />
      )}
    </span>
  );
};

export default Tag;
