import { useState } from "react";
type Props = {
  allowMultiple?: boolean;
  defaultValue?: string[];
};
export const useFilters = ({ allowMultiple = false, defaultValue = [] }: Props) => {
  const [filter, setFilter] = useState<string[]>(defaultValue);

  const onSelect = (value: string) => {
    if (filter.includes(value)) {
        setFilter(filter.filter((item) => item !== value));
    } else {
      if (allowMultiple) {
        setFilter([...filter, value]);
      } else {
        setFilter([value]);
      }
    }
  };

  return { filter, onSelect };
};
