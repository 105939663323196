import { Section } from "@components/UI-Elements/General";
import Title from "@components/UI-Elements/Typography/Title";
import React from "react";
import { followerWatchList } from "../../common/data/coinlist";
import { formatCurrency } from "../../common/helpers/formatter";
import { useFilters } from "../../common/hooks/useFilters";
import { ColumnConfig } from "../../common/types/typing";
import Layout from "../../components/layout";
import CoinList from "../../components/Modules/dashboard/coin-table";
import SEO from "../../components/seo";
import TagButton from "../../components/UI-Elements/Tag/TagButton";

const includeArray = (condition: boolean, array: any[]) => {
  return condition ? array : [];
}
const market: ColumnConfig<CoinData>[] = [
  {
    key: "price",
    title: "Preis",
    sort: (a: CoinData, b: CoinData) => b.priceUsd - a.priceUsd,
    render: (data) => formatCurrency(data?.priceUsd, "usd", "de-DE"),
  },
  {
    key: "marketCap",
    title: "MarketCap",
    sort: (a, b) => b.marketCap - a.marketCap,
    render: (data) => formatCurrency(data?.marketCap, "usd", "de-DE", false),
  },
]

const twitterColumns: ColumnConfig<CoinData>[] = [
  {
    key: "twitter",
    title: "Twitter",
    sort: (a, b) => b?.others?.community_data?.twitter_followers - a?.others?.community_data?.twitter_followers,
    render: (data) => data?.others?.community_data?.twitter_followers,
  },
]

const redditColumn: ColumnConfig<CoinData>[] = [
  {
    key: "reddit",
    title: "Reddit Subscribers",
    sort: (a, b) => b?.others?.community_data?.reddit_subscribers - a?.others?.community_data?.reddit_subscribers,
    render: (data) => data?.others?.community_data?.reddit_subscribers,
  },
  {
    key: "reddit48h",
    title: "Accounts 48h",
    render: (data) => data?.others?.community_data?.reddit_accounts_active_48h,
  },
  {
    key: "reddit48h",
    title: "Comments 48h",
    render: (data) => data?.others?.community_data?.reddit_average_comments_48h,
  },
  {
    key: "reddit48h",
    title: "Posts 48h",
    render: (data) => data?.others?.community_data?.reddit_average_posts_48h,
  },
]

const githubColumn: ColumnConfig<CoinData>[] = [
  {
    key: "stars",
    title: "Github Stars",
    sort: (a, b) => b?.others?.developer_data?.stars - a?.others?.developer_data?.stars,
    render: (data) => data?.others?.developer_data?.stars,
  },
  {
    key: "forks",
    title: "Github Forks",
    render: (data) => data?.others?.developer_data?.forks,
  },
  {
    key: "issues",
    title: "Github Issues",
    render: (data) => data?.others?.developer_data?.total_issues,
  },
  {
    key: "subscriber",
    title: "Github Subscriber",
    render: (data) => data?.others?.developer_data?.subscribers,
  },
]

const config = (options: string[]): ColumnConfig<CoinData>[] => {
  return [
    {
      key: "name",
      title: "Name",
      render: (data) => (
        <span>
          <img src={data?.image} alt={data?.name} className="w-5 inline mr-2" />
          {data?.name}
        </span>
      ),
    },
    ...includeArray(options.includes("market"), market),
    ...includeArray(options.includes("twitter"), twitterColumns),
    ...includeArray(options.includes("reddit"), redditColumn),
    ...includeArray(options.includes("github"), githubColumn)
  ];
};

const columnOptions = [
  { title: "Marktdaten", value: "market" },
  { title: "Twitter", value: "twitter" },
  { title: "Reddit", value: "reddit" },
  { title: "GitHub", value: "github" },
];

const Page: React.FC = () => {
  const { filter, onSelect } = useFilters({ allowMultiple: true, defaultValue: ["market", "twitter"] });
  const description =
    "Wie viele Twitter follower haben die einzelnen Kryptowährungen? Wie viel Aktivität herrscht auf Reddit? Und wie sieht es mit der Aktivität bei Github aus?";

  return (
    <Layout>
      <SEO keywords={["Twitter", "follower", "Kryptowährungen", "Github"]} title="Twitter Follower" description={description} />
      <Title>Die Community-Aktivität der einzelnen Coins</Title>
      <p>{description}</p>
      <Section>
        <div className="my-4">
          <strong>Spalten einblenden:</strong>
          {columnOptions.map((item) => (
            <TagButton key={item.value} value={item.value} title={item.title} onClick={onSelect} selected={filter.includes(item.value)} />
          ))}
        </div>
        <CoinList coins={followerWatchList} columnConfig={config(filter)} />
      </Section>
    </Layout>
  );
};

export default Page;
