import React, { ComponentProps } from "react";
import Tag from "./Tag";

type Props = Pick<ComponentProps<typeof Tag>, "color"> & {
  title: string;
  value?: string;
  onClick?: (key: string) => void;
  selected?: boolean;
  showClose?: boolean;
};

const TagButton: React.FC<Props> = ({
  title,
  value,
  onClick,
  selected,
  showClose,
}) => {
  const key = value || title;

  return (
    <button onClick={() => onClick?.(key)}>
      <Tag
        title={title}
        selected={selected}
        clickable
        showClose={showClose && selected}
      />
    </button>
  );
};

export default TagButton;
